import React, { useState } from 'react';
import SimilarDrawer from './@the-source/molecules/SimilarDrawer';
import HasSimilar from './@the-source/molecules/RecommendCard/HasSimilar';
import constants from 'src/utils/constants';
import useTenantSettings from 'src/hooks/useTenantSettings';
import { isEmpty } from 'lodash';

interface Props {
	drawer_props?: any;
	on_click?: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
	disable_drawer?: boolean;
	custom_similar_comp?: React.ReactNode;
	id: string;
}

const ViewSimilar = ({ drawer_props = {}, on_click, disable_drawer = false, custom_similar_comp, id }: Props) => {
	const [drawer, set_drawer] = useState(false);

	const { view_similar_enabled } = useTenantSettings({
		[constants.TENANT_SETTINGS_KEYS.VIEW_SIMILAR_ENABLED]: true,
	});

	const handle_view_similar_click = (e: any) => {
		on_click && on_click(e);
		set_drawer(true);
	};

	return (
		view_similar_enabled && (
			<>
				{!isEmpty(custom_similar_comp) ? custom_similar_comp : <HasSimilar onClick={handle_view_similar_click} id={id} />}
				{drawer && !disable_drawer && <SimilarDrawer drawer={drawer} setDrawer={set_drawer} {...drawer_props} />}
			</>
		)
	);
};

export default ViewSimilar;
