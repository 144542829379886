import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';
import CustomText from '../../CustomText';
import { colors } from 'src/utils/theme';

// type TooltipBaseProps = Pick<
// MuiTooltipProps
// >;

export interface TooltipProps extends MuiTooltipProps {}

const Tooltip = ({ children, title, textStyle, ...rest }: any) => (
	<MuiTooltip
		title={
			<CustomText color={colors.white} type='CaptionBold'>
				{title}
			</CustomText>
		}
		{...rest}>
		{children}
	</MuiTooltip>
);

Tooltip.defaultProps = {};
export default Tooltip;
