import React from 'react';
import styles from '../Cell.module.css';
import { Grid, Icon, Image, Typography } from 'src/common/@the-source/atoms';
import AchCellRenderer from 'src/screens/Payment/component/AchCellRenderer';
import { isEmpty } from 'lodash';
// Custom cell renderer for image type

interface Props {
	value: any;
	data: any;
}

const style = {
	backgroundColor: '#F7F8FA',
	padding: 10,
	borderRadius: 8,
	display: 'flex',
	alignItems: 'center',
	marginRight: 10,
};

const ImageTextCellRenderer: React.FC<Props> = ({ value, data, ...rest }) => {
	const tab = rest?.tab;
	const payment_method_type = data?.payment_method_type;
	const source = data?.source;
	const sub_title = data?.sub_title;

	if (
		(payment_method_type !== 'ach' && (['payments', 'recurring_payments'].includes(tab) || !isEmpty(payment_method_type))) ||
		(payment_method_type === 'ach' && source === 'manual')
	) {
		return (
			<Grid display={'flex'} className={styles.agGridCustomCell} alignItems={'center'}>
				{!isEmpty(value) && (
					<>
						{value?.image_src ? (
							<Image width={35} height={35} style={{ borderRadius: 8, marginRight: 10 }} src={value?.image_src} alt='test' />
						) : rest?.colDef?.field === 'payment_method_info' ? (
							'--'
						) : (
							<Grid style={style}>
								<Icon iconName='IconPhoto' color='grey' />
							</Grid>
						)}
						<Typography variant='subtitle1' sx={{ opacity: 0.6 }}>
							{value?.text_value}
						</Typography>
					</>
				)}
			</Grid>
		);
	}
	if (payment_method_type === 'ach' && (source === 'ach' || !isEmpty(sub_title))) {
		return <AchCellRenderer value={data?.sub_title} />;
	} else return '--';
};

export default ImageTextCellRenderer;
