import utils from '../utils';

interface Payload {
	realm_id: string;
	auth_code: string;
	redirect_uri: string;
}

export const integration = {
	get_integration_config: () => {
		return utils.request({
			url: 'integrations/v1/quickbook_online/auth',
			method: 'GET',
		});
	},

	update_integration_config: (data: Payload) => {
		return utils.request({
			url: '/integrations/v1/quickbook_online/auth/token',
			method: 'POST',
			data,
		});
	},
};
