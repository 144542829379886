import { Button, Grid, Image, Skeleton } from 'src/common/@the-source/atoms';
import classes from '../../Settings.module.css';
import CustomText from 'src/common/@the-source/CustomText';
import ImageLinks from 'src/assets/images/ImageLinks';
import { makeStyles } from '@mui/styles';
import { t } from 'i18next';
import { colors } from 'src/utils/theme';
import { useEffect, useMemo } from 'react';
import { error, success } from 'src/utils/light.theme';
import useIntegrationSetting from 'src/hooks/useIntegrationSetting';
import { useSelector } from 'react-redux';
import { ConnectionState, handle_connect, handle_connection_state } from './utils';

const useStyles = makeStyles(() => ({
	logo: {
		width: 40,
		height: 40,
	},
	quickbook_container: {
		borderRadius: '12px',
		padding: '16px 24px',
		marginTop: '16px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	quickbook_content_container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '16px',
	},
}));

const QBOIntegration = () => {
	const styles = useStyles();
	const {
		client_id = '',
		scope = '',
		loading,
		get_token_loading,
		is_refresh_token_expired,
		refresh_token_exists,
	} = useSelector((state: any) => state?.integration);
	const { get_tokens } = useIntegrationSetting();
	const connection_state = handle_connection_state({
		is_refresh_token_expired,
		refresh_token_exists,
	});

	const get_theme = (state: ConnectionState) => {
		switch (state) {
			case 'DISCONNECTED':
				return {
					header: t('Settings.Integration.QuickBookHeaderDisconnected'),
					body: t('Settings.Integration.QuickBookBody'),
					bg: error[50],
					btn: t('Settings.Integration.QuickBookButtonDisconnected'),
				};
			case 'CONNECTED':
				return {
					header: t('Settings.Integration.QuickBookHeaderConnected'),
					body: t('Settings.Integration.QuickBookBody'),
					bg: success[50],
					btn: '',
				};
			default:
				return {
					header: t('Settings.Integration.QuickBookHeader'),
					body: t('Settings.Integration.QuickBookBody'),
					bg: colors.grey_600,
					btn: t('Settings.Integration.QuickBookButton'),
				};
		}
	};

	const theme = useMemo(() => get_theme(connection_state), [connection_state]);

	const handle_quick_books = (
		<Grid
			className={styles.quickbook_container}
			sx={{
				backgroundColor: theme.bg,
			}}>
			<Grid className={styles.quickbook_content_container}>
				<Image src={ImageLinks.QuickBook} imgClass={styles.logo} alt='quick book' />
				<Grid>
					<CustomText type='H3'>{theme.header}</CustomText>
					<CustomText color={colors.secondary_text}>{theme.body}</CustomText>
				</Grid>
			</Grid>
			{connection_state !== 'CONNECTED' && <Button onClick={() => handle_connect(client_id, scope)}>{theme.btn}</Button>}
		</Grid>
	);

	const handle_skeleton = () => (
		<Grid>
			<Skeleton height={'80px'} sx={{ marginBottom: '16px', borderRadius: '8px' }} variant='rectangular' />
			<Skeleton width={'25%'} height={'10px'} sx={{ marginBottom: '12px', borderRadius: '8px' }} variant='rectangular' />
			<Skeleton width={'25%'} height={'10px'} sx={{ borderRadius: '8px' }} variant='rectangular' />
		</Grid>
	);

	useEffect(() => {
		get_tokens();
	}, []);

	return (
		<Grid className={classes.content}>
			<Grid
				className={classes.content_header}
				sx={{
					borderBottom: loading || get_token_loading ? '0px' : '1px solid rgba(0, 0, 0, 0.1)',
				}}>
				<CustomText type='H2'>Integration settings</CustomText>
			</Grid>
			{loading || get_token_loading ? handle_skeleton() : handle_quick_books}
		</Grid>
	);
};

export default QBOIntegration;
