import _, { head, isArray, isObject } from 'lodash';
import styles from '../Cell.module.css';
import utils from 'src/utils/utils';
import { Link } from 'react-router-dom';
import { Tooltip } from 'src/common/@the-source/atoms';
import CustomText from 'src/common/@the-source/CustomText';

interface Props {
	value: any;
	valueFormatted?: any;
	checkboxSelection?: boolean;
	clickable?: boolean;
	colDef?: any;
	node?: any;
}

const TextCellRenderer: React.FC<Props> = ({ value, ...rest }) => {
	let { valueFormatted, checkboxSelection, colDef, node } = rest;
	valueFormatted = isArray(value) ? head(value) : value;
	valueFormatted = isObject(valueFormatted) ? valueFormatted?.label || '' : valueFormatted || '';

	const is_hyper_link = _.get(colDef, 'cellRendererParams.displayType', '') === 'hyperLinks';
	const hyper_link_url = is_hyper_link ? utils.get_custom_hyper_link(colDef, node?.data) : '';

	if (checkboxSelection) {
		return;
	}

	if (String(valueFormatted).trim() === '') valueFormatted = '--';
	const content =
		valueFormatted === '--' ? (
			<div className={styles.agGridCustomCell}>
				<span>--</span>
			</div>
		) : (
			<div className={styles.agGridCustomCell}>
				<Tooltip title={valueFormatted} arrow placement='bottom'>
					<div style={{ width: 'max-content' }}>
						<CustomText
							id={valueFormatted}
							className={is_hyper_link ? styles.agGridCustomTextCell : ''}
							type={is_hyper_link ? 'Subtitle' : 'Body'}
							style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
							{valueFormatted}
						</CustomText>
					</div>
				</Tooltip>
			</div>
		);
	return is_hyper_link ? (
		<Link
			id={`${valueFormatted}_link`}
			to={hyper_link_url}
			className={styles.agGridCustomCellHyperLink}
			onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => utils.prevent_default_link_click(event)}>
			{content}
		</Link>
	) : (
		content
	);
};
export default TextCellRenderer;
