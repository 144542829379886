import styles from '../Cell.module.css';
import { Chip } from 'src/common/@the-source/atoms';
import { useState } from 'react';

import { Typography } from 'src/common/@the-source/atoms';
import MenuHover from 'src/common/MenuHover';
import { head, isEmpty, map } from 'lodash';

const ChipStyles = { cursor: 'pointer', fontSize: 12, border: '2px solid white' };

interface Props {
	value: any;
	valueFormatted?: any;
	displayType: string;
}

interface Option {
	value: any;
	label: string;
}

const MultiSelect2CellRenderer: React.FC<Props> = ({ value, displayType }) => {
	const [meta, set_meta] = useState([]);
	const [loading, set_loading] = useState(true);

	if (displayType === 'text') {
		const val_array = map(value, (v) => v.label).filter((v) => !isEmpty(v));
		return (
			<div className={styles.agGridCustomCell} style={{ marginTop: '-3px' }}>
				{isEmpty(val_array) ? '--' : val_array?.join(', ')}
			</div>
		);
	}

	const calculate_meta = () => {
		try {
			const transformed_meta = value?.map((item: Option) => {
				return {
					id: item.value,
					data: item,
				};
			});
			if (transformed_meta && transformed_meta?.length) set_meta(transformed_meta);
		} catch (error) {
		} finally {
			set_loading(false);
		}
	};

	return (
		<div className={styles.agGridCustomCell} style={{ marginTop: '-3px' }}>
			{value?.length > 1 ? (
				<MenuHover
					isScrollable={true}
					loading={loading}
					LabelComponent={
						<div style={{ cursor: 'pointer' }}>
							<Chip textColor='black' bgColor='#EEF1F7' label={value[0]?.label} sx={{ ...ChipStyles, marginRight: 1 }} />
							<Chip textColor='black' bgColor='#EEF1F7' label={`+${value?.length - 1}`} sx={ChipStyles} />
						</div>
					}
					commonMenuComponent={(_item: any) => {
						return <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>{_item?.data?.label}</Typography>;
					}}
					onOpen={calculate_meta}
					menu={meta}
				/>
			) : head(value)?.label?.trim() ? (
				<Chip textColor='black' bgColor='#EEF1F7' label={value?.[0]?.label} sx={{ ...ChipStyles, marginRigh: 1 }} />
			) : (
				<span className={styles.agGridCustomTextCell}>--</span>
			)}
		</div>
	);
};

export default MultiSelect2CellRenderer;
