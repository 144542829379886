import { Grid, Menu, MenuList } from '@mui/material';
import { useState } from 'react';

import { Icon, Typography } from '../../../atoms';
import CategoryFilter from './CategoryFilter';
import styles from './CategoryFilter.module.css';
import { CategoryProps } from './CategoryFilter.types';

const Category = ({ label, categoryList, onApply, applied = [] }: CategoryProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const dropdownOpen = Boolean(anchorEl);

	const handleBoxClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Grid
				id='category-filter'
				container
				className={styles['category-filter-box']}
				sx={{
					border: !anchorEl && applied?.length === 0 ? 'none' : '1px #16885F solid',
					padding: !anchorEl && applied?.length === 0 ? '0.75em 1em' : '0.7em 1em',
					'&:hover': { border: applied.length === 0 ? '1px solid black' : '' },
				}}
				direction='row'
				minWidth={150}
				height={42}
				justifyContent='space-between'
				alignItems='center'
				onClick={(e: any) => {
					handleBoxClick(e);
				}}>
				<Grid item>
					{applied?.length > 0 && <span className={styles['red-dot']} />} <Typography variant='body-2'>{label}</Typography>
				</Grid>
				<Grid item alignItems='center'>
					<Icon iconName={anchorEl ? 'IconChevronUp' : 'IconChevronDown'} sx={{ marginTop: '.2em' }} />
				</Grid>
			</Grid>
			<Menu
				open={dropdownOpen}
				onClose={handleMenuClose}
				anchorEl={anchorEl}
				sx={{
					height: '100vh',
					// overflow: 'scroll',
					// '&::-webkit-scrollbar': {
					// 	display: 'none',
					// },
					pt: 0,
					minWidth: 200,
				}}>
				<MenuList
					sx={{
						maxHeight: 300,
						minHeight: 100,
						overflow: 'scroll',
						'&::-webkit-scrollbar': {
							display: 'none',
						},
						minWidth: 300,
						p: 0,
						mt: '-.5em',
						mb: '-.5em',
						'&:focus-visible': {
							outline: 'none',
						},
					}}>
					<CategoryFilter categoryList={categoryList} onApply={onApply} applied={applied} setAnchorEl={setAnchorEl} />
				</MenuList>
			</Menu>
		</>
	);
};

Category.defaultProps = {
	label: 'Category',
	applied: [],
};
export default Category;
