import { useState, useEffect, useRef } from 'react';
import { Document, Thumbnail, pdfjs } from 'react-pdf';
import classes from '../css/PdfViewer.module.css';
import CustomText from 'src/common/@the-source/CustomText';
import { useInView } from 'react-intersection-observer';
import VirtualList from 'src/common/VirtualList';
import size from 'lodash/size';
import nth from 'lodash/nth';

function PageComponent({ index, data, set_page_number, config }: any) {
	const [meta, set_meta] = useState<any>([]);

	const { ref } = useInView({
		threshold: 0.6,
		triggerOnce: false,
		onChange: (inView) => {
			if (inView) {
				set_page_number(data);
			}
		},
	});

	useEffect(() => {
		if (config) {
			const fetchLinks = async () => {
				const page = await config.getPage(index + 1);

				const viewport = page.getViewport({ scale: 1 });
				const pageHeight = viewport.height;

				const textContent = await page.getTextContent();
				const items = textContent.items || [];

				const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;

				const detectedLinks = items
					.map((item: any) => {
						const matches = item.str.match(urlRegex);
						if (matches) {
							return matches.map((url: any) => {
								const x = nth(item?.transform, 4);
								const y: number = nth(item?.transform, 5) ?? 0;

								return {
									url: url.startsWith('www.') ? `https://${url}` : url,
									left: x,
									top: pageHeight - y - item?.height,
									width: item?.width,
									height: item?.height,
								};
							});
						}
						return null;
					})
					.flat()
					.filter(Boolean);

				set_meta(detectedLinks);
			};

			fetchLinks();
		}
	}, [config]);

	return (
		<div ref={ref} key={data} data-page={data} className='pdf-page' style={{ position: 'relative' }}>
			<Thumbnail pageNumber={data} />
			{size(meta) > 0 &&
				meta.map((link: any) => {
					return (
						<a
							href={link.url}
							target='_blank'
							rel='noopener noreferrer'
							style={{
								position: 'absolute',
								left: `${link.left}px`,
								top: `${link.top}px`,
								width: `${link.width}px`,
								height: `${link.height}px`,
								zIndex: 9999999,
							}}
						/>
					);
				})}
		</div>
	);
}

export default function PdfViewer({ max_pages = 1, ...props }: any) {
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

	const [page_number, set_page_number] = useState(1);
	const [page_thumbnails, set_page_thumbnails] = useState<number[]>([]);
	const observer_ref = useRef<HTMLDivElement | null>(null);
	const left_container_ref = useRef<HTMLDivElement | null>(null);
	const [document_loading, set_document_loading] = useState(true);
	const [config, set_config] = useState<any>(null);

	function onDocumentLoadSuccess(pdf_config: any) {
		set_config(pdf_config);
		let temp = Array.from({ length: pdf_config?.numPages }, (_, i) => i + 1);
		set_page_thumbnails(temp);
		set_page_number(1);
		set_document_loading(false);
	}

	const height = '90vh';

	useEffect(() => {
		if (left_container_ref.current) {
			const activeThumbnail = left_container_ref.current.querySelector(`.thumbnail-${page_number}`);
			if (activeThumbnail) {
				activeThumbnail.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
			}
		}
	}, [page_number]);

	const handleThumbnailClick = (page: number) => {
		set_page_number(page);
		const rightImage = observer_ref.current?.querySelector(`.pdf-page[data-page='${page}']`);
		if (rightImage) {
			if (page === 1) {
				rightImage.scrollTo(0, -100);
			} else {
				rightImage.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
	};

	useEffect(() => {
		if (document_loading) return;
		const timer = setTimeout(() => {
			set_page_number(1);
		}, 100);

		return () => clearTimeout(timer);
	}, [document_loading]);

	return (
		<>
			<div id='main-cont' style={{ height: props?.height ?? height }} className={classes.main_cont}>
				<Document file={props?.file_url} onLoadSuccess={onDocumentLoadSuccess}>
					<div style={{ display: 'flex', flexDirection: 'row', width: props?.width ?? '96vw' }}>
						{!props?.is_left_hidden && (
							<div
								ref={left_container_ref}
								style={{
									height: props?.height ?? height,
									overflowY: 'auto',
									scrollBehavior: 'smooth',
								}}
								className={classes.lft_cont}>
								{page_thumbnails.map((data, indx) => (
									<div
										key={`item-${indx}`}
										className={`${classes.thumbnail_cont} thumbnail-${data}`}
										style={{
											border: data === page_number ? '1px solid #9aa0aa' : 'none',
											minHeight: '15vh',
										}}
										onClick={() => handleThumbnailClick(data)}>
										<Thumbnail pageNumber={data} width={130} />
										<CustomText className={classes.page_number}>{data}</CustomText>
									</div>
								))}
							</div>
						)}

						<div
							ref={observer_ref}
							style={{
								height: props?.height ?? height,
								overflowY: 'auto',
								scrollBehavior: 'smooth',
							}}
							className={props?.right_cont ?? classes.right_cont}>
							{max_pages > 1 || max_pages === -1 ? (
								<VirtualList
									render_item={(thumbnail_data: any, index: number) => {
										return (
											<div
												style={{
													paddingTop: index === 0 ? '0px' : '16px',
												}}>
												<PageComponent data={thumbnail_data} index={index} config={config} set_page_number={set_page_number} />
											</div>
										);
									}}
									data={page_thumbnails}
									item_key={(item: any) => item}
								/>
							) : (
								<Thumbnail className={classes.cursor_style} pageNumber={page_number} />
							)}
						</div>
					</div>
				</Document>
			</div>
		</>
	);
}
