import { UPDATE_CONFIG } from '../actions/reduxConstants';

interface Config {
	client_id: string;
	is_refresh_token_expired: boolean;
	refresh_token_exists: boolean;
	scope: string;
	loading: boolean;
	get_token_loading: boolean;
}

const initialState: Config = {
	client_id: '',
	is_refresh_token_expired: false,
	refresh_token_exists: false,
	scope: '',
	loading: true,
	get_token_loading: false,
};

const integration_reducer = (state: any = initialState, action: any) => {
	switch (action.type) {
		case UPDATE_CONFIG:
			return {
				...state,
				...action?.data,
			};
		default:
			return state;
	}
};

export default integration_reducer;
