import CustomCellRenderer from './CustomCellRenderer';
import CustomCellEditor from './CustomCellEditor';
import formatValueByDtype from '../Helpers/formatValueByDtype';
import CustomTooltip from './CustomTooltip';
import { isEmpty, lowerCase } from 'lodash';
import formatValueByDtypeSSRM from '../Helpers/formatValueByDtypeSSRM';
import constants from 'src/utils/constants';
import { convert_date_to_timezone } from 'src/utils/dateUtils';

interface Props {
	filterType: string;
	children?: Props[];
	headerClass?: string;
	cellRenderer: any;
	cellEditor: any;
	menuTabs?: string[];
	cellRendererParams?: { type: string; [key: string]: any };
	filter?: string | boolean;
	floatingFilter?: string | boolean;
	dtype: string;
	cellStyle: any;
	cellEditorPopup: any;
	valueFormatter: any;
	field: string;
	type?: any;
	compositeKey?: string;
	flex?: any;
	tooltipComponent?: any;
	minWidth?: number;
	lockPosition?: boolean | string;
	hideFilter?: boolean;
	filterParams: any;
	tooltipField: any;
	headerComponentParams?: any;
	sortable: boolean;
	width?: number;
	resizable?: boolean;
	isSortable?: boolean;
	headerName: string;
	unSortIcon?: boolean;
	displayType?: string;
	pinned?: string;
	suppressMovable?: boolean;
	headerStyle?: any;
}

const columnFilterLookup: { [key: string]: { filter: string | boolean; floatingFilter?: string } } = {
	text: { filter: 'agTextColumnFilter', floatingFilter: 'agTextColumnFilter' },
	image: { filter: false },
	price: { filter: 'agMultiColumnFilter', floatingFilter: 'agNumberColumnFilter' },
	tag: { filter: 'agMultiColumnFilter', floatingFilter: 'agTextColumnFilter' },
	tags: { filter: 'agMultiColumnFilter', floatingFilter: 'agTextColumnFilter' },
	tags2: { filter: 'agMultiColumnFilter', floatingFilter: 'agNumberColumnFilter' },
	table: { filter: false },
	textarea: { filter: 'agMultiColumnFilter', floatingFilter: 'agTextColumnFilter' },
	date: { filter: 'agDateColumnFilter', floatingFilter: 'agDateColumnFilter' },
	date_only: { filter: 'agDateColumnFilter', floatingFilter: 'agDateColumnFilter' },
	singleSelect: { filter: 'agMultiColumnFilter', floatingFilter: 'agTextColumnFilter' },
	multiselect: { filter: 'agMultiColumnFilter', floatingFilter: 'agTextColumnFilter' },
	size: { filter: 'agMultiColumnFilter', floatingFilter: 'agTextColumnFilter' },
	uom: { filter: 'agMultiColumnFilter', floatingFilter: 'agTextColumnFilter' },
	url: { filter: 'agMultiColumnFilter', floatingFilter: 'agTextColumnFilter' },
	html: { filter: 'agMultiColumnFilter', floatingFilter: 'agTextColumnFilter' },
	composition: { filter: 'agMultiColumnFilter', floatingFilter: 'agTextColumnFilter' },
	number: { filter: 'agNumberColumnFilter', floatingFilter: 'agNumberColumnFilter' },
	decimal: { filter: 'agMultiColumnFilter', floatingFilter: 'agNumberColumnFilter' },
	range: { filter: 'agMultiColumnFilter', floatingFilter: 'agNumberColumnFilter' },
	currency: { filter: 'agMultiColumnFilter', floatingFilter: 'agNumberColumnFilter' },
	status: { filter: 'agSetColumnFilter', floatingFilter: 'agTextColumnFilter' },
	internal_status: { filter: 'agSetColumnFilter', floatingFilter: 'agTextColumnFilter' },
	select: { filter: 'agSetColumnFilter', floatingFilter: 'agTextColumnFilter' },
	dateTime: { filter: 'agDateColumnFilter', floatingFilter: 'agDateColumnFilter' },
	email: { filter: 'agTextColumnFilter', floatingFilter: 'agTextColumnFilter' },
	phone: { filter: 'agTextColumnFilter', floatingFilter: 'agTextColumnFilter' },
	longText: { filter: 'agTextColumnFilter', floatingFilter: 'agTextColumnFilter' },
	imageText: { filter: 'agTextColumnFilter', floatingFilter: 'agTextColumnFilter' },
	percentage: { filter: 'agNumberColumnFilter', floatingFilter: 'agNumberColumnFilter' },
};

const columnSSRMFilterLookup: { [key: string]: { filter: string | boolean; floatingFilter?: string; filterParams?: any } } = {
	text: { filter: 'agTextColumnFilter' },
	image: { filter: false },
	price: { filter: 'agNumberColumnFilter' },
	bulkPricing: { filter: false },
	tags: { filter: 'agSetColumnFilter' },
	tag: { filter: 'agSetColumnFilter' },
	tags2: { filter: 'agNumberColumnFilter' },
	table: { filter: false },
	textarea: { filter: 'agTextColumnFilter' },
	date: { filter: 'agDateColumnFilter' },
	date_only: { filter: 'agDateColumnFilter' },
	unixdate: { filter: 'agDateColumnFilter' },
	singleselect: { filter: 'agSetColumnFilter' },
	singleSelect: { filter: 'agSetColumnFilter' },
	multiselect: { filter: 'agSetColumnFilter' },
	size: { filter: false },
	uom: { filter: 'agTextColumnFilter' },
	url: { filter: 'agTextColumnFilter' },
	html: { filter: 'agTextColumnFilter' },
	composition: { filter: 'agTextColumnFilter' },
	number: { filter: 'agNumberColumnFilter' },
	decimal: { filter: 'agNumberColumnFilter' },
	range: { filter: 'agNumberColumnFilter' },
	length: { filter: 'agNumberColumnFilter' },
	width: { filter: 'agNumberColumnFilter' },
	height: { filter: 'agNumberColumnFilter' },
	unit: { filter: 'agTextColumnFilter' },
	value: { filter: 'agNumberColumnFilter' },
	status: { filter: 'agSetColumnFilter' },
	internal_status: { filter: 'agSetColumnFilter' },
	currency: { filter: 'agNumberColumnFilter' },
	percentage: { filter: 'agNumberColumnFilter' },
	select: { filter: 'agSetColumnFilter' },
	dateTime: { filter: 'agDateColumnFilter' },
	email: { filter: 'agTextColumnFilter' },
	phone: { filter: 'agTextColumnFilter' },
	longText: { filter: 'agTextColumnFilter' },
	imageText: { filter: 'agTextColumnFilter' },
};

const applyCustomProps = (column: Props): void => {
	let { dtype } = column;
	if (isEmpty(dtype)) column.dtype = column?.type;

	if (lowerCase(column.headerName) === 'order tag') {
		column.dtype = 'select';
		column.displayType = 'iconChip';
	}

	column.cellRenderer = column?.cellRenderer ? column.cellRenderer : CustomCellRenderer;
	column.cellEditor = column?.cellEditor ? column.cellEditor : CustomCellEditor;
	if (!column.menuTabs) column.menuTabs = ['generalMenuTab'];
	column.cellEditorPopup = true;
	column.valueFormatter = column?.valueFormatter ?? formatValueByDtype(dtype || '');

	if (dtype !== 'action') {
		column.cellStyle = { width: 200, minWidth: 100, overflow: 'hidden', ...column?.cellStyle };
		column.headerStyle = { width: 200, minWidth: 100, overflow: 'hidden', ...column?.headerStyle };
		column.minWidth = column?.minWidth ?? 100;
		column.width = column?.width ?? 200;
	} else if (dtype === 'action') {
		column.width = column?.width ?? 80;
		column.minWidth = 80;
		column.cellStyle = { width: 80, minWidth: 80 };
	}
	column.resizable = true;
	column.cellRendererParams = { type: dtype || '', ...column };
	column.tooltipComponent = CustomTooltip;

	column.unSortIcon = true;
	column.sortable = column.isSortable ?? false;

	column.headerComponentParams = {
		template: `<div class="ag-cell-label-container" role="presentation">
			<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
			<div ref="eLabel" class="ag-header-cell-label" role="presentation">
				<span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
				<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
				<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
				<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
				<span ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
				<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
				<span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
			</div>
		</div>`,
	};

	if (dtype && columnFilterLookup[dtype] && !column.filter) {
		const { filter, floatingFilter } = columnFilterLookup[dtype];
		column.filter = filter;
		column.floatingFilter = floatingFilter;
	}

	if (constants.TEXT_FILTER_DTYPE.includes(column?.dtype) && column.filterType !== 'set') {
		column.filter = 'agTextColumnFilter';
		column.floatingFilter = 'agTextColumnFilter';
		column.filterParams = {
			filterOptions: ['contains', 'notContains', 'equals', 'blank', 'notBlank'],
			maxNumConditions: 1,
			...column?.filterParams,
		};
	} else if (constants.DATE_FILTER_DTYPE.includes(column?.dtype)) {
		column.filter = 'agDateColumnFilter';
		column.floatingFilter = 'agDateColumnFilter';
		column.filterParams = {
			comparator: (filterDate: Date, cellValue: string) => {
				if (!cellValue) return -1;

				const format = constants.ATTRIBUTE_DATE_TIME_FORMAT;
				const convertedCellDate = convert_date_to_timezone(cellValue, format);

				if (!convertedCellDate) return -1;

				const normalizedFilterDate = new Date(filterDate.setHours(0, 0, 0, 0));
				const normalizedCellDate = new Date(new Date(convertedCellDate).setHours(0, 0, 0, 0));

				if (normalizedCellDate.getTime() === normalizedFilterDate.getTime()) {
					return 0;
				}
				return normalizedCellDate.getTime() > normalizedFilterDate.getTime() ? 1 : -1;
			},
			filterOptions: ['equals', 'lessThanOrEqual', 'greaterThanOrEqual', 'lessThan', 'greaterThan', 'inRange'],
			inRangeFloatingFilterDateFormat: 'D MMM YYYY',
			inRangeInclusive: true,
			maxNumConditions: 1,
			...column?.filterParams,
		};
	} else if (constants.NUMBER_FILTER_DTYPE.includes(column?.dtype)) {
		column.filter = 'agNumberColumnFilter';
		column.floatingFilter = 'agNumberColumnFilter';

		column.filterParams = {
			filterOptions: ['equals', 'lessThanOrEqual', 'greaterThanOrEqual', 'lessThan', 'greaterThan', 'inRange'],
			inRangeInclusive: true,
			maxNumConditions: 1,
			...column?.filterParams,
		};
	} else if (constants.SELECT_FILTER_DTYPE.includes(column?.dtype)) {
		column.filter = 'agSetColumnFilter';
		column.floatingFilter = 'agTextColumnFilter';
	}

	if (column.hideFilter) {
		column.filter = false;
		column.floatingFilter = false;
	}

	column.filterParams = {
		...column.filterParams,
		buttons: ['reset', 'apply'],
		closeOnApply: true,
	};
};

const applySSRMCustomProps = (column: Props): void => {
	let { dtype, compositeKey } = column;
	if (isEmpty(dtype)) column.dtype = column?.type;

	if (lowerCase(column.headerName) === 'order tag') {
		column.dtype = 'select';
		column.displayType = 'iconChip';
	}

	column.cellRenderer = column?.cellRenderer ? column.cellRenderer : CustomCellRenderer;
	column.cellEditor = column?.cellEditor ? column.cellEditor : CustomCellEditor;
	column.suppressMovable = !isEmpty(column?.pinned);

	if (!column.menuTabs) column.menuTabs = ['generalMenuTab'];
	column.cellEditorPopup = true;
	column.valueFormatter = formatValueByDtypeSSRM(dtype || '');

	if (dtype !== 'action') {
		column.cellStyle = { width: column?.width ?? 200, minWidth: 100, overflow: 'hidden', ...column?.cellStyle };
		column.headerStyle = { width: column?.width ?? 200, minWidth: 100, overflow: 'hidden', ...column?.headerStyle };
		column.minWidth = column?.minWidth ?? 100;
		column.width = column?.width ?? 200;
	} else if (dtype === 'action') {
		column.width = column?.width ?? 80;
		column.minWidth = 80;
		column.cellStyle = { width: 80, minWidth: 80 };
	}

	column.cellRendererParams = { type: dtype || '', ...column };
	column.tooltipComponent = CustomTooltip;
	column.headerComponentParams = {
		template: `<div class="ag-cell-label-container" role="presentation">
        <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
        <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="${
					column?.headerName === '#' ? 'display: flex; justify-content: center;' : ''
				}">
            <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
            <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
            <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
            <span ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
            <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
            <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
        </div>
    </div>`,
	};

	if (dtype && columnSSRMFilterLookup[dtype] && !column.filter) {
		let key = dtype;
		if (compositeKey) {
			key = compositeKey;
		}
		const { filter, floatingFilter } = columnSSRMFilterLookup[key];
		column.filter = filter;
		column.floatingFilter = floatingFilter;
	}

	if (constants.NUMBER_FILTER_DTYPE.includes(column?.dtype)) {
		column.filter = 'agNumberColumnFilter';
		column.filterParams = {
			filterOptions: ['equals', 'lessThanOrEqual', 'greaterThanOrEqual', 'lessThan', 'greaterThan', 'inRange'],
			...column?.filterParams,
		};
	} else if (constants.TEXT_FILTER_DTYPE.includes(column?.dtype) && column.filterType !== 'set') {
		column.filter = 'agTextColumnFilter';
		column.filterParams = {
			filterOptions: ['contains', 'notContains', 'equals', 'blank', 'notBlank'],
		};
	} else if (constants.DATE_FILTER_DTYPE.includes(column?.dtype)) {
		column.filter = 'agDateColumnFilter';
		column.filterParams = {
			...column?.filterParams,
			filterOptions: ['equals', 'lessThanOrEqual', 'greaterThanOrEqual', 'lessThan', 'greaterThan', 'inRange'],
			inRangeFloatingFilterDateFormat: 'D MMM YYYY',
		};
	} else if (constants.SELECT_FILTER_DTYPE.includes(column?.dtype)) {
		column.filter = 'agSetColumnFilter';
	} else {
		column.filterParams = {
			filterOptions: ['contains'],
			...column?.filterParams,
		};
	}

	column.filterParams = {
		...column.filterParams,
		buttons: ['reset', 'apply'],
		closeOnApply: true,
		maxNumConditions: 1,
	};

	if (column.hideFilter) {
		column.filter = false;
		column.floatingFilter = false;
	}
};

export const applyCustomRenderer = (columns: Props[], subColumnClass: string, parentColor: number | null = null): Props[] => {
	let alternateColorIndex = parentColor ? parentColor + 1 : 0;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const applyAlternateColor = (_col: Props): void => {
		columns.forEach((column: Props) => {
			if (column.children && column.children.length > 0) {
				column.headerClass = 'custom-group-header-color';
			}
			if (subColumnClass) {
				column.headerClass = subColumnClass;
			} else {
				// Apply the alternate color to the current column
				column.headerClass = `custom-header-color-${alternateColorIndex % 2 === 0 ? 'even' : 'odd'}`;
				alternateColorIndex++;
			}
		});
	};

	columns.forEach(applyAlternateColor);
	columns.forEach((column: Props) => {
		alternateColorIndex++;
		if (column.children && column.children.length > 0) {
			// Apply cell renderer recursively to the children columns
			applyCustomRenderer(column.children, 'sub-custom-header-color');
		} else {
			applyCustomProps(column);
		}
	});
	return columns;
};

export const applySSRMCustomRenderer = (columns: Props[], parentColor: number | null = null): Props[] => {
	let alternateColorIndex = parentColor ? parentColor + 1 : 0;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const applyAlternateColor = (_col: Props, index: number): void => {
		columns.forEach((column: Props) => {
			if (column.children && column.children.length > 0) {
				column.headerClass = 'custom-group-header-color';
			}
			// Apply the alternate color to the current column
			column.headerClass = `custom-header-color-${alternateColorIndex % 2 === 0 ? 'even' : 'odd'}`;
			alternateColorIndex++;
		});
	};

	columns.forEach(applyAlternateColor);
	columns.forEach((column: Props) => {
		alternateColorIndex++;
		if (column.children && column.children.length > 0) {
			applySSRMCustomRenderer(column.children);
		} else {
			applySSRMCustomProps(column);
		}
	});
	return columns;
};
