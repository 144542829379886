import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps, styled } from '@mui/material';
import Grid from '@mui/material/Grid';

import { primary, secondary } from '../../../../utils/light.theme';
import { IconNames } from '../Icon/baseTypes';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import Typography from '../Typography/Typography';

type IconButtonBaseProps = Pick<MuiIconButtonProps, 'color' | 'disabled' | 'name' | 'sx'>;

export interface SidebarIconProps extends IconButtonBaseProps {
	name: IconNames;

	type?: 'rounded' | 'square' | 'circle';
	clicked?: boolean;

	size?: 'small' | 'medium' | 'large';

	text?: string;
	fullWidth?: boolean;
	hasChildren?: boolean;
	expandIcon?: boolean;
	is_icon?: boolean;
	active_name?: any;
	id: string;
	is_child?: boolean;
	enable_small_font?: boolean;
}

const SidebarIconClicked = styled(MuiIconButton)({
	color: '#FFF',
	backgroundColor: primary[600],
	':hover': {
		backgroundColor: primary[600],
		color: '#FFF',
	},
});

const SidebarIcon = ({
	name,
	clicked,
	disabled,
	type,
	size,
	text,
	fullWidth,
	hasChildren,
	expandIcon,
	active_name,
	is_icon = true,
	id,
	is_child = false,
	enable_small_font = false,
	...rest
}: SidebarIconProps) => {
	const getBorderRadius = (): number => {
		switch (type) {
			case 'rounded':
				return 8;
			case 'square':
				return 0;
			case 'circle':
				return 50;
			default:
				return 8;
		}
	};

	const container_style = {
		...(fullWidth ? { width: '100%' } : {}),
		borderRadius: getBorderRadius(),
		padding: is_child ? '10.2px 0px 10.2px 2.25em' : '',
	};

	return clicked ? (
		<SidebarIconClicked id={`sidebar-item-${id}`} size={size} style={container_style} {...rest}>
			{is_icon ? <Icon fontSize='medium' color='#FFF' iconName={name} /> : <Image src={clicked ? active_name || name : name} />}
			{text && (
				<Grid container sx={{ marginLeft: '.5em' }}>
					<Typography color='#FFF' variant={is_child || enable_small_font ? 'body1' : 'h6'}>
						{text}
					</Typography>
				</Grid>
			)}
			{hasChildren &&
				!!text &&
				(expandIcon ? <Icon color='#FFF' iconName='IconChevronDown' /> : <Icon color='#FFF' iconName='IconChevronRight' />)}
		</SidebarIconClicked>
	) : (
		<MuiIconButton
			id={`sidebar-item-${id}`}
			disabled={disabled}
			color='secondary'
			disableRipple
			style={container_style}
			size={size}
			{...rest}>
			{is_icon ? <Icon fontSize='medium' color='#676D77' iconName={name} /> : <Image src={name} />}
			{text && (
				<Grid container sx={{ marginLeft: '.5em' }}>
					<Typography color={secondary[800]} variant='body1'>
						{text}
					</Typography>
				</Grid>
			)}
			{hasChildren &&
				!!text &&
				(expandIcon ? <Icon color='#676D77' iconName='IconChevronDown' /> : <Icon color='#676D77' iconName='IconChevronRight' />)}
		</MuiIconButton>
	);
};

SidebarIcon.defaultProps = {
	type: 'rounded',
	disabled: false,
	clicked: false,
	size: 'medium',
	onClick: () => {},
	text: '',
	fullWidth: false,
	hasChildren: false,
	expandIcon: false,
};
export default SidebarIcon;
