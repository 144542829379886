import React from 'react';
import styles from '../Cell.module.css';
import { Chip, Grid } from 'src/common/@the-source/atoms';
import utils from 'src/utils/utils';
import { isObject } from 'lodash';
import CustomText from 'src/common/@the-source/CustomText';

interface Props {
	value: string[];
	valueFormatted?: any;
}

const ChipCellRenderer: React.FC<Props> = ({ value, ...rest }) => {
	let { valueFormatted } = rest;

	valueFormatted = isObject(valueFormatted) ? valueFormatted?.label : valueFormatted;

	const { textColor, bgColor, fontWeight = 500 } = utils.get_chip_color_by_tag(String(valueFormatted?.toLowerCase()));

	const chip_style = {
		marginRight: 1,
		fontSize: 12,
		border: '2px solid white',
		textTransform: 'capitalize',
		marginBottom: '9px',
		height: '28px',
	};

	return (
		<div className={styles.agGridCustomCell}>
			{valueFormatted ? (
				<Chip
					textColor={textColor}
					bgColor={bgColor}
					label={
						<Grid container alignItems='center'>
							{valueFormatted}
						</Grid>
					}
					sx={{ ...chip_style, fontWeight }}
				/>
			) : (
				<CustomText>--</CustomText>
			)}
		</div>
	);
};

export default ChipCellRenderer;
