import utils from '../utils';

const notifications = {
	get_task_list: (data: any) => {
		return utils.request({
			url: '/tasks/v1/list',
			method: 'POST',
			mock: false,
			data,
		});
	},
	notification_action: (action: string, task_id: string) => {
		return utils.request({
			url: '/tasks/v1/action',
			method: 'POST',
			data: {
				action,
				task_id,
			},
		});
	},
};

export default notifications;
