import CustomText from 'src/common/@the-source/CustomText';
import styles from '../Cell.module.css';

interface Props {
	value: any;
}

const PercentageCellRenderer: React.FC<Props> = ({ value }) => {
	return (
		<div className={styles.agGridCustomCell}>
			<CustomText>{value ? `${value}%` : '--'}</CustomText>
		</div>
	);
};

export default PercentageCellRenderer;
