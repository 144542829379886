import Typography from '../../atoms/Typography/Typography';
import { Grid } from '../../atoms';
import SidebarIcon from '../../atoms/SidebarIcon';

export interface SidebarMenuProps {
	title: string;
	childItems: any;
	handleClick: (id: number | string) => {};
}
const SidebarTooltip = ({ title, childItems, handleClick }: SidebarMenuProps) => {
	const validate_active = (link: string) => {
		const url_location = window.location.pathname;
		return url_location.includes(link) && link !== '/';
	};

	return (
		<>
			<Grid
				sx={{
					borderBottom: '1px solid #0000001F',
					paddingBottom: '6px',
					marginBottom: '6px',
				}}>
				<Typography noWrap variant='h6'>
					{title}
				</Typography>
			</Grid>
			<Grid container flexDirection={'column'} gap={'6px'}>
				{childItems?.map((child: any) => {
					const is_clicked = validate_active(child?.link);
					return (
						<Grid
							key={child.id}
							onClick={(event) => {
								event.stopPropagation();
								handleClick(child);
								child?.onIconClick();
							}}>
							<SidebarIcon
								id={`${title}-subitem-${child?.id}`}
								text={child?.title}
								hasChildren={child?.children?.length > 0}
								name={child?.icon}
								active_name={child?.icon_active}
								key={child?.id}
								clicked={is_clicked}
								fullWidth={true}
								is_icon={child?.is_icon ?? true}
								enable_small_font={true}
							/>
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};

export default SidebarTooltip;
