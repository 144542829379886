import { Checkbox as MuiCheckBox, CheckboxProps as MuiCheckBoxProps } from '@mui/material';

type CheckBoxBaseProps = Pick<MuiCheckBoxProps, 'size' | 'checked' | 'disabled' | 'onChange' | 'onClick'>;

export interface CheckboxProps extends CheckBoxBaseProps {
	id?: string;
	checked?: boolean;
	size?: any;
	onChange?: any;
	disabled?: boolean;
	inputRef?: any;
	name?: any;
	indeterminate?: boolean;
	sx?: any;
	className?: any;
}

const Checkbox = ({ checked, size, disabled, onChange, inputRef, name, indeterminate, sx, className, ...rest }: CheckboxProps) => (
	<MuiCheckBox
		name={name}
		size={size}
		indeterminate={indeterminate}
		checked={checked}
		disabled={disabled}
		onChange={onChange}
		inputRef={inputRef}
		sx={sx}
		className={className}
		{...rest}
	/>
);

Checkbox.defaultProps = {
	size: 'medium',
	checked: false,
	onChange: () => {},
	disabled: false,
};
export default Checkbox;
