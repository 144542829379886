import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { primary } from '../../../../utils/light.theme';

type ChipBaseProps = Pick<MuiChipProps, 'size' | 'label' | 'variant' | 'onDelete' | 'deleteIcon' | 'sx' | 'color'>;

export interface ChipProps extends ChipBaseProps {
	id?: string;
	size?: any;
	label?: string | React.ReactNode;
	variant?: any;
	deleteIcon?: React.ReactElement;
	onDelete?: any;
	onClick?: any;
	bgColor?: string;
	textColor?: string;
	avatar?: React.ReactElement;
	className?: any;
	disabled?: boolean;
	icon?: React.ReactElement;
}

const Chip = ({
	size,
	label,
	variant,
	onDelete,
	deleteIcon,
	bgColor,
	textColor,
	sx,
	className,
	avatar,
	disabled,
	icon,
	...rest
}: ChipProps) => (
	<MuiChip
		size={size}
		label={label}
		avatar={avatar}
		icon={icon}
		deleteIcon={deleteIcon}
		onDelete={onDelete}
		variant={variant}
		className={className}
		disabled={disabled}
		sx={{
			// MuiChip color prop only accepts certain enums.
			...sx,
			background: bgColor,
			color: textColor,
		}}
		{...rest}
	/>
);

Chip.defaultProps = {
	size: 'medium',
	label: '',
	variant: '',
	deleteIcon: null,
	onDelete: '',
	bgColor: primary[400],
	textColor: primary.contrastText,
};

export default Chip;
