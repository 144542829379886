const TIMEZONES = [
	{
		label: '(GMT -3:30) Newfoundland Standard Time (Canada)',
		value: 'America/St_Johns',
		offset: 'GMT -3:30',
	},
	{
		label: '(GMT -4:00) Atlantic Standard Time',
		value: 'America/Puerto_Rico',
		offset: 'GMT -4:00',
	},
	{
		label: '(GMT -5:00) Eastern Standard Time',
		value: 'America/New_York',
		is_default: true,
		offset: 'GMT -5:00',
	},
	{
		label: '(GMT +0:00) Coordinated Universal Time (UTC)',
		value: 'UTC',
		offset: '0000',
	},
	{
		label: '(GMT -6:00) Central Standard Time',
		value: 'America/Chicago',
		offset: 'GMT -6:00',
	},
	{
		label: '(GMT -7:00) Mountain Standard Time',
		value: 'America/Denver',
		offset: 'GMT -7:00',
	},
	{
		label: '(GMT -8:00) Pacific Standard Time',
		value: 'America/Los_Angeles',
		offset: 'GMT -8:00',
	},
	{
		label: '(GMT -9:00) Alaska Standard Time',
		value: 'America/Anchorage',
		offset: 'GMT -9:00',
	},
	{
		label: '(GMT -10:00) Hawaii Standard Time',
		value: 'Pacific/Honolulu',
		offset: 'GMT -10:00',
	},
	{
		label: '(GMT -11:00) Samoa Standard Time',
		value: 'Pacific/Pago_Pago',
		offset: 'GMT -11:00',
	},
	{
		label: '(GMT +5:30) Indian Standard Time',
		value: 'Asia/Kolkata',
		offset: 'GMT +5:30',
	},
];

export default TIMEZONES;
