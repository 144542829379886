import React from 'react';
import styles from '../Cell.module.css';
import StatusChip from 'src/common/@the-source/atoms/Chips/StatusChip';
import utils from 'src/utils/utils';
import CustomText from 'src/common/@the-source/CustomText';
import { isObject } from 'lodash';

interface Props {
	value?: string[];
	valueFormatted?: any;
}

const StatusCellRenderer: React.FC<Props> = ({ value, ...rest }) => {
	let { valueFormatted } = rest;

	valueFormatted = isObject(valueFormatted) ? valueFormatted?.label : valueFormatted;

	return (
		<div className={styles.agGridCustomCell} style={{ marginTop: '-3px' }}>
			{value ? (
				<StatusChip
					statusColor={utils.get_chip_color_by_status(valueFormatted)}
					textStyle={{ fontSize: '1.4rem' }}
					sx={{ padding: '2px 10px', lineHeight: 'normal' }}
					label={valueFormatted === 'Accepted' ? 'Converted to order' : valueFormatted}
				/>
			) : (
				<CustomText>--</CustomText>
			)}
		</div>
	);
};

export default StatusCellRenderer;
