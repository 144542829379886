import CustomText from 'src/common/@the-source/CustomText';
import styles from '../Cell.module.css';

interface Props {
	value: any;
	data: any;
}

const PhoneCellRenderer: React.FC<Props> = ({ data }) => {
	const phone_number = data?.phone;
	// const country = data?.country_code;

	// const formatted_value = phone_number ? `${country} ${phone_number}` : '--';

	return (
		<div className={styles.agGridCustomCell}>
			<CustomText>{phone_number ? phone_number : '--'}</CustomText>
		</div>
	);
};

export default PhoneCellRenderer;
