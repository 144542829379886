import { makeStyles } from '@mui/styles';
import { primary, secondary } from 'src/utils/light.theme';
import { colors } from 'src/utils/theme';

export const useStyles = makeStyles(() => ({
	card: {
		width: '289px',
		height: '93px',
		borderRadius: '8px',
		padding: '12px',
		display: 'flex',
		gap: 3,
		alignItems: 'center',
		cursor: 'pointer',
	},
	selected_card: {
		border: `1px solid ${primary[500]}`,
	},
	not_selected_card: {
		border: `1px solid ${secondary[400]}`,
	},
	cancel_btn: {
		color: colors.grey_800,
		border: `1px solid ${secondary[500]}`,
		'&:hover': {
			border: `1px solid ${secondary[500]}`,
		},
	},
	field: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
		padding: '0px 0px 16px 0px',
		borderBottom: `1px dashed ${secondary[500]}`,
	},
	required: {
		color: colors.red,
	},
	drawer_container: {
		background: 'white',
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
		padding: '16px 20px',
	},
}));
