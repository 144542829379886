import { useState } from 'react';
import { Menu as MUIMenu, MenuItem as MUIMenuItem, MenuProps as MUIMenuProps } from '@mui/material';

type MenuBase = Pick<
	MUIMenuProps,
	'onClick' | 'className' | 'disablePortal' | 'anchorEl' | 'open' | 'onClose' | 'anchorOrigin' | 'transformOrigin'
>;

export interface Item {
	id: string;
	component?: React.ReactNode;
	onClick?: any;
	data?: any;
}

export interface MenuProps extends MenuBase {
	id?: string;
	LabelComponent: React.ReactNode;
	menu: Item[];
	commonMenuComponent?: any;
	commonMenuOnClickHandler?: any;
	closeOnItemClick?: boolean;
	hover?: any;
	selectedId?: any;
	setValue?: any;
	position?: 'left' | 'right';
	hideGreenBorder?: boolean;
	disable?: boolean;
}

const menuStyles = {
	boxShadow: '0px 8px 40px 0px rgba(0, 0, 0, 0.08)',
	borderRadius: '0.8rem',
	background: '#FFF',
};

const Menu = ({
	id = 'menu',
	menu,
	commonMenuComponent,
	LabelComponent,
	commonMenuOnClickHandler,
	closeOnItemClick,
	selectedId = '',
	setValue = () => {},
	position = 'left',
	hideGreenBorder = false,
	hover,
	disable,
	...rest
}: MenuProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);

	const handleClick: React.MouseEventHandler<HTMLDivElement> = (event: React.MouseEvent<HTMLDivElement>) => {
		if (disable) return;
		setAnchorEl(event.currentTarget);
		setValue(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setValue(null);
	};

	const handleMenuItem = (item: Item) => {
		if (item.onClick) {
			item.onClick(item);
		}
		if (commonMenuOnClickHandler) commonMenuOnClickHandler(item);
		if (closeOnItemClick) handleClose();
	};
	return (
		<div id={id}>
			<div
				style={{ border: anchorEl && !selectedId && !hideGreenBorder ? '1px solid #16885f' : 'none', borderRadius: '10px' }}
				onClick={handleClick}>
				{LabelComponent}
			</div>
			<MUIMenu
				PaperProps={{ style: menuStyles }}
				anchorEl={anchorEl}
				{...rest}
				onClose={handleClose}
				open={open}
				sx={{ margin: '.6rem', zIndex: '+10000' }}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: position,
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: position,
				}}>
				{menu?.map(
					(item: any): JSX.Element => (
						<MUIMenuItem
							disabled={item?.disabled}
							onClick={() => handleMenuItem(item)}
							key={item.id}
							id={item.id}
							sx={{
								background: `${selectedId === item?.id ? 'rgba(22, 136, 95, 0.08)' : 'transparent'} !important`,
								'&:hover': {
									backgroundColor: !hover && 'transparent', // This removes the hover background color
								},
							}}>
							{item.component ? item.component : commonMenuComponent(item)}
						</MUIMenuItem>
					),
				)}
			</MUIMenu>
		</div>
	);
};

Menu.defaultProps = {
	onClick: () => {},
	closeOnItemClick: false,
};
export default Menu;
