export type ConnectionState = 'CONNECTED' | 'DISCONNECTED' | null;
interface Payload {
	refresh_token_exists?: Boolean;
	is_refresh_token_expired?: Boolean;
}

export const handle_connection_state = (data: Payload): ConnectionState => {
	if (!data?.refresh_token_exists) {
		return null;
	}
	if (data?.is_refresh_token_expired) {
		return 'DISCONNECTED';
	} else {
		return 'CONNECTED';
	}
};

export const get_redirect_uri = () => `${window.location.origin}/settings/integration`;
const get_url = (client_id: string, scope: string) => {
	return `https://appcenter.intuit.com/connect/oauth2?client_id=${client_id}&response_type=code&scope=${scope}&redirect_uri=${get_redirect_uri()}&state=security_token%3D138r5719ru3e1%26url%3D${get_redirect_uri()}`;
};

export const handle_connect = async (client_id: string, scope: string) => {
	const request_url = get_url(client_id, scope);
	try {
		window.open(request_url, '_parent');
	} catch (err) {
		console.error(err);
	}
};
