import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { update_integration_config } from 'src/actions/integration';
import { get_redirect_uri } from 'src/screens/Settings/components/Integration/utils';
import { integration } from 'src/utils/api_requests/integration';

const useIntegrationSetting = () => {
	const dispatch = useDispatch();

	const get_config = () => {
		dispatch<any>(
			update_integration_config({
				loading: true,
			}),
		);
		integration
			.get_integration_config()
			.then((res: any) => {
				dispatch<any>(
					update_integration_config({
						...(res?.data ?? {}),
					}),
				);
			})
			.catch((err) => console.error(err))
			.finally(() =>
				dispatch<any>(
					update_integration_config({
						loading: false,
					}),
				),
			);
	};

	const get_tokens = () => {
		const url_prams = new URLSearchParams(window.location.search);
		const temp_code = url_prams.get('code') ?? '';
		const temp_realmid = url_prams.get('realmId') ?? '';

		if (isEmpty(temp_code) || isEmpty(temp_realmid)) return;

		dispatch<any>(
			update_integration_config({
				get_token_loading: true,
			}),
		);
		integration
			.update_integration_config({
				auth_code: temp_code,
				realm_id: temp_realmid,
				redirect_uri: get_redirect_uri(),
			})
			.then(() => {
				get_config();
			})
			.catch((err) => console.error(err))
			.finally(() => {
				dispatch<any>(
					update_integration_config({
						get_token_loading: false,
					}),
				);
				window.history.replaceState({}, '', `${window.location.pathname}`);
			});
	};

	return {
		get_tokens,
		get_config,
	};
};

export default useIntegrationSetting;
